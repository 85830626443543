<template>
  <div>
    <div class="flex">
      <div class="w-5/6">
        <h2 class="pb-3">Agency Dashboard</h2>
      </div>
    </div>
  </div>
</template>

<script>
import Validator from "../../common/Validator";
import {USER_INVITE} from "../../../store/actions/user";
export default {
  name: "DashboardHeader",
  components: {
           
        },
  data() {
    return {
      showNewVendor: false,
      parentGc: '',
      newUser: {
              name: '',
              email: ''
          }
    }
  },
  methods: {
    checkVendorName() {
                // console.log(this.vendors);
                // console.log(this.newVendor.name);
            },
    saveNewUser() {
            if(!Validator([
                {field: this.newUser.name, name: 'Name'},
                {field: this.newUser.email, name: 'Email'},
            ])) {
                return;
            }
            this.$store.dispatch(USER_INVITE, this.newUser).then(() => {
                        this.showNewVendor = false;
                        this.$notify({
                        title: 'success',
                        message: 'User invitation send successfully',
                        type: 'success'
                    });
                    }).catch(); 
    },        
    _getCounts() {
      this.$store
        .dispatch("UTILITY_INIT_DASHBOARD_COUNTS")
        .then(() => {})
        .catch();
    },
  },
  computed: {
    counts: function () {
      return this.$store.getters.getDashboardCounts;
    },
  },
  mounted() {
    // if (localStorage.getItem('parent-gc') != 'null') {
      this.parentGc = localStorage.getItem('parent-gc');
      // console.log("================>", this.parentGc);
    // }
    this._getCounts();
  },
};
</script>

<style scoped>
.export-link {
  color: #8bb3e4;
  text-align: center;
  display: block;
  margin-top: -15px;
}
h2 {
  color: #255897;
  font-family: "Open Sans", sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 34px;
  text-transform: uppercase;
}
.dashHeader {
  min-height: 238px;
}
.folder-text {
  margin-top: -10px;
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.1px;
  text-align: center;
}
.folder-count {
  padding-top: 34px;
  font-family: "Open Sans", sans-serif;
  font-size: 44px;
  font-weight: 600;
  text-align: center;
  text-shadow: 0 2px 4px rgba(46, 46, 46, 0.5);
}
.main-folder {
  background-image: url("~@/assets/images/active-folder.svg");
  background-repeat: no-repeat;
}
.main-folder .folder-count {
  color: #ffffff;
}
.main-folder .folder-text {
  color: #b2bfff;
}
.inactive-folder {
  background-image: url("~@/assets/images/inactive-folder.svg");
  background-repeat: no-repeat;
}
.inactive-folder .folder-count {
  color: #255897;
}
.inactive-folder .folder-text {
  color: #8bb3e4;
}
/*xs*/
@media (max-width: 640px) {
  .folder-count {
    font-size: 16px;
    padding-top: 10px;
  }
  .folder-text {
    font-size: 14px;
  }
  .dashHeader {
    min-height: 80px;
  }
}
/*sm*/
@media (min-width: 640px) {
  .folder-count {
    font-size: 16px;
    padding-top: 10px;
  }
  .folder-text {
    font-size: 14px;
  }
  .dashHeader {
    min-height: 120px;
  }
}
/*md*/
@media (min-width: 768px) {
  .folder-count {
    font-size: 20px;
    padding-top: 20px;
  }
  .dashHeader {
    min-height: 170px;
  }
}
/*lg*/
@media (min-width: 1024px) {
  .folder-count {
    font-size: 38px;
    padding-top: 28px;
  }
  .dashHeader {
    min-height: 200px;
  }
}
/*xl*/
@media (min-width: 1280px) {
  .folder-count {
    font-size: 44px;
    padding-top: 34px;
  }
  .dashHeader {
    min-height: 238px;
  }
}
</style>
