<template>
    <div>
        <nav class="flex items-center justify-between flex-wrap bg-white p-6">
            <div class="flex items-center flex-shrink-0 text-white mr-6">
                <router-link :to = "{name: 'projects'}">
                    <img class = 'logo' src = '../../../assets/images/header-logo.png' />
                </router-link>
            </div>
            <div class="block lg:hidden">
                <button class="flex items-center px-3 py-2 border rounded ">
                    <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
                </button>
            </div>
            <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto text-right">
                <div class="text-sm lg:flex-grow">
                    <a @click = "logout" class="logout nav-link block mt-1 lg:inline-block lg:mt-0 mr-4 cursor-pointer">Logout</a>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
    import {AUTH_LOGOUT} from "../../../store/actions/auth";
    export default {
        name: 'Header',
        methods: {
            getLink(link) {
                return process.env.VUE_APP_ROOT + link;
            },
            logout() {
                this.$store.dispatch(AUTH_LOGOUT).then(() => {
			window.location = this.getLink('/app/logout');
			this.$router.push('/login');
                }).catch();
            }
        }
    }
</script>

<style scoped>
    .logo {
        max-height: 58px;
    }
    .nav-link{
        color: #255897;
        font-family: Muli,serif;
        font-size: 20px;
        font-weight: 200;
        line-height: 30px;
    }
    .active{
        font-weight: bold;
        border-bottom: 4.25px solid #FBB830;
    }
    .logout{
        height: 29px;
        width: 80px;
        border-radius: 8px;
        background-color: #E3E8FF;
        padding-top: 8px;
        color: #255897;
        font-family: Roboto,serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 13px;
        text-align: center;
    }
</style>
