<template>
    <div class = 'container mx-auto'>
        <Header />
        <div class = 'container main-container min-h-screen p-8'>
            <router-view/>
        </div>
    </div>
</template>

<script>
    import Header from "../components/vendor/partial/Header";
    export default {
        name: 'Gc',
        components: {
            Header
        },
        mounted() {
        }
    }
</script>

<style scoped>
    .main-container{
        background-color: #FAFAFA;
        min-height: 1000px;
    }
    .container {
        max-width: 100% !important;
    }
</style>